import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import _ from 'lodash';
import {
  ApiBridgeWithIncludes,
  ApiCameraWithIncludes,
  GetBridgeParams,
  GetLocationParams,
  LocationGet,
  LPRActorFilter,
} from '@eencloud/eewc-components/src/service/api-types';
import { Item } from '@eencloud/eewc-components/src/components/dropdowns/types';

import { useLPREventsStore } from './lprEvents';
import { useBridgesStore } from './bridges';
import { useCamerasStore } from './cameras';
import { useLocationsStore } from './locations';

import { VSPRoute } from '@/pages/VSP/types';
import { getSitesAndCameraIDsFromActors, getStringDropdownOption } from '@/pages/VSP/utils';
import { getEmptySelectOption } from '@/pages/POS/utils';

import { t } from '@/plugins/i18n';

/**
 * Types
 */

type VSPRouteDependency = 'sites' | 'bridges' | 'cameras';

/**
 * Store
 */

export const useVSPStore = defineStore('vsp', () => {
  const cameras = ref<ApiCameraWithIncludes[]>([]);
  const isLoadingCameras = ref<boolean>(true);
  const lprEventsStore = useLPREventsStore();
  const bridgesStore = useBridgesStore();
  const camerasStore = useCamerasStore();
  const locationsStore = useLocationsStore();
  const isVSPReportsEnabled = ref<boolean>(false);
  const isVSPMapsEnabled = ref<boolean>(false);

  const getAllVSPEnabledCameras = async () => {
    const CAMERA_INCLUDES: string[] = ['status', 'locationSummary', 'deviceAddress', 'packages', 'devicePosition'];

    try {
      isLoadingCameras.value = true;

      await camerasStore.getAllCamerasByCustomParam({
        include: CAMERA_INCLUDES.toString(),
        packages__contains: 'een.vsp',
        'shareDetails.shared': false,
      });

      cameras.value = camerasStore.allCamerasByCustomParam;
    } catch {
      cameras.value = [];
    } finally {
      isLoadingCameras.value = false;
    }
  };

  const bridges = ref<ApiBridgeWithIncludes[]>([]);
  const isLoadingBridges = ref<boolean>(false);

  const getAllBridges = async (params: GetBridgeParams) => {
    try {
      isLoadingBridges.value = true;

      bridges.value = await bridgesStore.getAllBridgesByCustomParam(params);
    } catch {
      bridges.value = [];
    } finally {
      isLoadingBridges.value = false;
    }
  };

  const sites = ref<LocationGet[]>([]);
  const isLoadingSites = ref<boolean>(false);

  const getAllSites = async (params?: GetLocationParams) => {
    try {
      isLoadingSites.value = true;

      const queryParams = params ?? {};

      await locationsStore.getAllLocationsByCustomParam(queryParams);

      sites.value = locationsStore.locations;
    } catch {
      sites.value = [];
    } finally {
      isLoadingSites.value = false;
    }
  };

  const getRouteDependencies = (route: VSPRoute) => {
    const ROUTE_API_DEPENDENCIES: Record<VSPRoute, VSPRouteDependency[]> = {
      addRule: ['cameras', 'sites'],
      addVehicle: [],
      alerts: ['cameras'],
      editRule: ['cameras', 'sites'],
      editVehicle: [],
      rules: ['cameras', 'sites'],
      search: ['cameras'],
      singleVehicleList: ['cameras'],
      summary: ['bridges', 'cameras'],
      vehicleList: ['cameras', 'sites'],
      vehicleListCSVUpload: [],
    };

    const currentRouteAPIDependencies = ROUTE_API_DEPENDENCIES[route];

    currentRouteAPIDependencies?.forEach((dependency) => {
      switch (dependency) {
        case 'cameras':
          if (!cameras.value.length) {
            getAllVSPEnabledCameras();
          }
          break;

        case 'sites':
          if (!sites.value.length && lprEventsStore.isSiteGroupingEnabled) {
            getAllSites();
          }
          break;

        case 'bridges':
          if (!bridges.value.length) {
            getAllBridges({});
          }
          break;
      }
    });
  };

  // Cameras array converted into map object
  const camerasInfoMap = computed<Record<string, ApiCameraWithIncludes>>(() => {
    const map: Record<string, ApiCameraWithIncludes> = {};

    cameras.value.forEach((camera) => (map[camera.id] = camera));

    return map;
  });

  // Sites array converted into map object
  const sitesInfoMap = computed<Record<string, LocationGet>>(() => {
    const map: Record<string, LocationGet> = {};

    sites.value.forEach((site) => {
      const id = site.id;

      if (id) {
        map[id] = site;
      }
    });

    return map;
  });

  // Bridges array converted into map object
  const bridgesInfoMap = computed<Record<string, ApiBridgeWithIncludes>>(() => {
    const map: Record<string, ApiBridgeWithIncludes> = {};

    bridges.value.forEach((bridge) => (map[bridge.id] = bridge));

    return map;
  });

  const cameraFilterOptions = computed(() => {
    const options = cameras.value.reduce((acc, cameraID) => {
      const camera = camerasInfoMap.value[cameraID.id];

      if (camera) {
        acc.push(
          getStringDropdownOption({
            text: camera.name,
            value: cameraID.id,
          })
        );
      }

      return acc;
    }, [] as Item[]);

    return [
      {
        name: '',
        items: _.sortBy(options, ['text']),
      },
    ];
  });

  const NO_SITE_KEY = 'No site';

  const siteAndChildCameraFilterOptions = computed<Record<string, Item[]>>(() => {
    const NoSiteGroupKey = t(NO_SITE_KEY);

    const map = cameraFilterOptions.value[0].items.reduce((acc, option) => {
      const cameraID = option.value;
      const camera = camerasInfoMap.value[cameraID];

      if (camera) {
        const siteName = lprEventsStore.isSiteGroupingEnabled
          ? camera.locationSummary?.name?.trim()
          : camera.deviceAddress?.name?.trim();

        const key = siteName || NoSiteGroupKey;

        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(option);
      }

      return acc;
    }, {} as Record<string, Item[]>);

    return map;
  });

  const createSiteFilterMap = (cameraOptions: { value: string }[]): Record<string, Item> => {
    const siteFilterMap = cameraOptions.reduce((acc, option) => {
      const cameraID = option.value;
      const camera = camerasInfoMap.value[cameraID];

      if (camera) {
        const siteID = camera.locationSummary?.id;
        const siteName = lprEventsStore.isSiteGroupingEnabled
          ? camera.locationSummary?.name
          : camera.deviceAddress?.name;
        const shouldUseLocationSummary = lprEventsStore.isSiteGroupingEnabled && siteID;

        if (shouldUseLocationSummary && !acc[siteID]) {
          acc[siteID] = getStringDropdownOption({
            text: siteName ?? siteID,
            value: siteID,
          });
        } else if (!shouldUseLocationSummary && siteName && !acc[siteName]) {
          acc[siteName] = getStringDropdownOption({ text: siteName, value: siteName });
        }
      }

      return acc;
    }, {} as Record<string, Item>);

    return siteFilterMap;
  };
  const siteFilterOptions = computed(() => {
    const siteFilterMap = createSiteFilterMap(cameraFilterOptions.value[0]?.items ?? []);
    const options: Item[] = Object.values(siteFilterMap);

    if (NO_SITE_KEY in siteAndChildCameraFilterOptions.value) {
      options.push(getEmptySelectOption(NO_SITE_KEY));
    }

    return [
      {
        name: '',
        items: _.sortBy(options, ['text']),
      },
    ];
  });

  const enableReportsAndMaps = (data: boolean) => {
    isVSPReportsEnabled.value = data;
    isVSPMapsEnabled.value = data;
  };

  const getSitesFromActors = (actors: LPRActorFilter[]) => {
    const { cameraIDs, siteIDs } = getSitesAndCameraIDsFromActors(actors);
    const siteOptions: string[] = siteIDs.length
      ? siteIDs.reduce((options, id) => {
          options.push(sitesInfoMap.value[id] as string);
          return options;
        }, [] as string[])
      : cameraIDs.length
      ? (() => {
          const siteFilterMap = createSiteFilterMap(cameraIDs.map((id) => ({ value: id })));
          return Object.values(siteFilterMap).reduce((options, item) => {
            options.push(item.text);
            return options;
          }, [] as string[]);
        })()
      : [];

    return siteOptions;
  };

  return {
    bridges,
    bridgesInfoMap,
    cameraFilterOptions,
    cameras,
    camerasInfoMap,
    createSiteFilterMap,
    enableReportsAndMaps,
    getAllBridges,
    getAllSites,
    getAllVSPEnabledCameras,
    getSitesFromActors,
    getRouteDependencies,
    isLoadingBridges,
    isLoadingCameras,
    isLoadingSites,
    isVSPMapsEnabled,
    isVSPReportsEnabled,
    siteAndChildCameraFilterOptions,
    siteFilterOptions,
    sites,
    sitesInfoMap,
    NO_SITE_KEY,
  };
});
