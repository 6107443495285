import _ from 'lodash';
import dayjs from 'dayjs';

import {
  ApiBridgeIncludes,
  ApiCameraIncludes,
  ApiSpeakerIncludes,
  ResourceStatusCounts,
} from '@eencloud/eewc-components/src/service/api-types';
import { EventCluster } from '@/pages/VideoSearch/types';
import { t } from '@/plugins/i18n.ts';
import router from '@/service/router';
type routerParams = { name: string; params?: { [key: string]: string } };
import { SidebarOption } from '@/types';

export function openInNewTab(href: string) {
  //could go to helper functions
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: href,
  }).click();
}

export function goFullScreen() {
  document.documentElement.requestFullscreen();
}

export function statusContent(connectionStatus: string | undefined, device: string = 'camera') {
  if (device === 'bridge') {
    if (connectionStatus === 'online') {
      return { icon: '$icon_online', tooltip: t('Online') };
    } else {
      return { icon: '$icon_offline', tooltip: t('Offline') };
    }
  } else {
    switch (connectionStatus) {
      case 'REDY': // for speaker connection Status is different, which needs to be checked with api team
      case 'online':
        return { icon: '$icon_online', tooltip: t('Online') };
      case 'off':
        return { icon: '$icon_off', tooltip: t('Turned off') };
      case 'deviceOffline':
        return { icon: '$icon_offline', tooltip: t('Offline') };
      case 'bridgeOffline':
        return { icon: '$icon_internet_offline', tooltip: t('Internet offline') };
      case 'error':
        if (device === 'speaker') {
          return { icon: '$icon_unavailable', tooltip: t('Could not communicate to the cloud') };
        } else return { icon: '$icon_attention', tooltip: t('Error') };
      case 'unknown':
        if (device === 'speaker') {
          return { icon: '$icon_unavailable', tooltip: t('Could not communicate to the cloud') };
        } else return { icon: '$icon_help', tooltip: t('Status unknown') };
      case 'invalidCredentials':
        return {
          icon: '$icon_attention',
          tooltip: t(`Bridge could not authenticate the ${device}`),
        };
      default:
        return { icon: '$icon_help', tooltip: 'Status unknown' };
    }
  }
}

export function timeSince(date: number, addSuffix = true) {
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = day * 365;

  const suffix = t('ago');

  const elapsed = Math.floor((Date.now() - date) / 1000);

  if (elapsed < minute) {
    return addSuffix ? t('just now') : '';
  }

  const text = (elapsed < hour && [
    Math.floor(elapsed / minute),
    Math.floor(elapsed / minute) === 1 ? t('minute') : t('minutes'),
  ]) ||
    (elapsed < day && [Math.floor(elapsed / hour), Math.floor(elapsed / hour) === 1 ? t('hour') : t('hours')]) ||
    (elapsed < month && [Math.floor(elapsed / day), Math.floor(elapsed / day) === 1 ? t('day') : t('days')]) ||
    (elapsed < year && [Math.floor(elapsed / month), Math.floor(elapsed / month) === 1 ? t('month') : t('months')]) || [
      Math.floor(elapsed / year),
      Math.floor(elapsed / year) === 1 ? t('year') : t('years'),
    ];
  return text[0] + ' ' + text[1] + (addSuffix ? ' ' + suffix : '');
}

export function getNotificationIcon(item: string) {
  switch (item) {
    case 'audit':
      return '$icon_notification_audit';
    case 'health':
      return '$icon_camera';
    case 'job':
      return '$icon_job';
    case 'operational':
      return '$icon_operational';
    case 'security':
      return '$icon_security';
    case 'sharing':
      return '$icon_share';
    case 'video':
      return '$icon_camera';
    default:
      return '$icon_notification_audit';
  }
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

const monthNames = [
  t('January'),
  t('February'),
  t('March'),
  t('April'),
  t('May'),
  t('June'),
  t('July'),
  t('August'),
  t('September'),
  t('October'),
  t('November'),
  t('December'),
];

const weekDayNames = [
  t('Sunday'),
  t('Monday'),
  t('Tuesday'),
  t('Wednesday'),
  t('Thursday'),
  t('Friday'),
  t('Saturday'),
].map((day) => day.slice(0, 1));

export const datePickerProps = {
  monthNames,
  weekDayNames,
  sidebarOptions: [
    t('Today'),
    t('Yesterday'),
    t('This week'),
    t('Last week'),
    t('Last 2 weeks'),
    t('This month'),
    t('Last month'),
    t('Custom'),
  ],
};

export const dateTimePickerProps = {
  monthNames,
  weekDayNames,
  sidebarOptions: [
    { text: t('Last 24 hours'), option: 'last24Hours' },
    { text: t('Today'), option: 'today' },
    { text: t('Yesterday'), option: 'yesterday' },
    { text: t('Last 7 days'), option: 'last7Days' },
    { text: t('Last 30 days'), option: 'last30Days' },
  ] as SidebarOption[],
};

export function generateTimestamp(currentTime: number) {
  if (!currentTime || currentTime > Date.now()) {
    return new Date().toISOString().replace('Z', '+00:00');
  } else {
    return new Date(currentTime).toISOString().replace('Z', '+00:00');
  }
}

// Convert timestamp -  20221130110647.387 to Date.-  Wed Nov 30 2022 12:06:48 GMT+0100 (Central European Standard Time)
export function timestampToDate(ts: string) {
  const yy = parseInt(ts.substring(0, 4), 10);
  const mm = parseInt(ts.substring(4, 6), 10);
  const dd = parseInt(ts.substring(6, 8), 10);
  const hr = parseInt(ts.substring(8, 10), 10);
  const mn = parseInt(ts.substring(10, 12), 10);
  const sc = parseInt(ts.substring(12, 14), 10);
  const ms = parseInt(ts.substring(15), 10);
  const date = new Date(Date.UTC(yy, mm - 1, dd, hr, mn, sc, ms));
  return date;
}

export function getAlpha2Countries(): Array<string> {
  return [
    'AD',
    'AE',
    'AF',
    'AG',
    'AI',
    'AL',
    'AM',
    'AO',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AW',
    'AX',
    'AZ',
    'BA',
    'BB',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BI',
    'BJ',
    'BL',
    'BM',
    'BN',
    'BO',
    'BQ',
    'BR',
    'BS',
    'BT',
    'BV',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CC',
    'CD',
    'CF',
    'CG',
    'CH',
    'CI',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CU',
    'CV',
    'CW',
    'CX',
    'CY',
    'CZ',
    'DE',
    'DJ',
    'DK',
    'DM',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EG',
    'EH',
    'ER',
    'ES',
    'ET',
    'FI',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'GA',
    'GB',
    'GD',
    'GE',
    'GF',
    'GG',
    'GH',
    'GI',
    'GL',
    'GM',
    'GN',
    'GP',
    'GQ',
    'GR',
    'GS',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HM',
    'HN',
    'HR',
    'HT',
    'HU',
    'ID',
    'IE',
    'IL',
    'IM',
    'IN',
    'IO',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JE',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LK',
    'LR',
    'LS',
    'LT',
    'LU',
    'LV',
    'LY',
    'MA',
    'MC',
    'MD',
    'ME',
    'MF',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MQ',
    'MR',
    'MS',
    'MT',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NF',
    'NG',
    'NI',
    'NL',
    'NO',
    'NP',
    'NR',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PG',
    'PH',
    'PK',
    'PL',
    'PM',
    'PN',
    'PR',
    'PS',
    'PT',
    'PW',
    'PY',
    'QA',
    'RE',
    'RO',
    'RS',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SG',
    'SH',
    'SI',
    'SJ',
    'SK',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'SS',
    'ST',
    'SV',
    'SX',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TF',
    'TG',
    'TH',
    'TJ',
    'TK',
    'TL',
    'TM',
    'TN',
    'TO',
    'TR',
    'TT',
    'TV',
    'TW',
    'TZ',
    'UA',
    'UG',
    'UM',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WF',
    'WS',
    'YE',
    'YT',
    'ZA',
    'ZM',
    'ZW',
  ];
}

export function removeMilliseconds(val: string) {
  const time = val.trim().split(' ');
  const timeWithoutMs = time[0].indexOf('.') !== -1 ? time[0].slice(0, time[0].indexOf('.')) : time[0];
  return `${timeWithoutMs} ${time[1] ?? ''}`;
}

/**
 * convert date in string to EEN timestamp format
 * @param val : string YYYY-MM-DDTHH:MM:SSZ or YYYY-MM-DDTHH:MM.SSZ
 * @returns EEN timestamp format YYYY-MM-DD HH:MM:SS AM
 */
export function convertIsoToLocalizedTime(val: string) {
  if (!val?.length) {
    return;
  }
  const lastLogin = new Date(val).toEENAccountTimeStamp();
  const date = lastLogin.substring(0, 10);
  return `${date} ${removeMilliseconds(lastLogin.slice(10))}`;
}

export function getPriorityLabel(value: number) {
  return _.inRange(value, 1, 4)
    ? t('low')
    : _.inRange(value, 4, 7)
    ? t('medium')
    : _.inRange(value, 7, 10)
    ? t('high')
    : t('alarm');
}

/**
 * convert size in bytes to KB, MB, GB
 *
 * @param size
 * @returns formatted value
 */
export function convertNumberToBytes(size: string) {
  let l = 0,
    n = parseInt(size, 10) || 0;
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n ? n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l] : '-';
}

/**
 * function will capitalize first character of input msg string
 * @param msg
 * @returns
 */
export function capitalizeFirstLetter(msg: string) {
  return msg.charAt(0).toUpperCase() + msg.slice(1);
}

/**
 * function to find the difference between 2 dates in number of days
 *
 * @param date1
 * @param date2
 * @returns
 */
export function getDateDifference(date1: Date, date2: Date): number {
  const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  // Convert both dates to UTC to account for daylight saving time differences
  const utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  // Calculate the difference in days
  const diffDays = Math.floor((utcDate2 - utcDate1) / oneDay);

  return diffDays;
}

// convert ms to hour minutes seconds
export const convertMillisecondsToHMS = (milliseconds: number) => ({
  hours: Math.floor((milliseconds / (1000 * 60 * 60)) % 24),
  minutes: Math.floor((milliseconds / (1000 * 60)) % 60),
  seconds: Math.floor((milliseconds / 1000) % 60),
});

/**
 *
 * @description
 * Get number of seconds from timepicker input
 *
 *
 * @param {string} time provide time in format: YYYY-MM-DD
 * @return {number}
 */
export function getSecondsFromTime(time: string): number {
  const dateObject = new Date(time);
  const oneHourInSeconds = 3600;
  const oneMinuteInSeconds = 60;

  return (
    dateObject.getHours() * oneHourInSeconds + dateObject.getMinutes() * oneMinuteInSeconds + dateObject.getSeconds()
  );
}

/**
 * function to convert seconds to hours, minutes and seconds format
 *
 * @param seconds
 * @returns formattedTime converted Time
 */
export const convertSecondsToHMSText = (seconds: number | undefined) => {
  if (!seconds || isNaN(seconds)) {
    return '';
  }

  /**
   * function to add singular/plural text for specific time format
   *
   * @param time
   * @param singularText
   * @param pluralText
   * @returns
   */
  function addTime(time: number, singularText: string, pluralText: string) {
    if (time === 1) {
      formattedTime += `${time} ${singularText}`;
    } else {
      formattedTime += `${time} ${pluralText}`;
    }
  }

  let formattedTime = '';
  const HMS = convertMillisecondsToHMS(seconds * 1000);

  if (seconds < 60) {
    addTime(HMS.seconds, t('second'), t('seconds'));
  } else if (seconds < 3600) {
    addTime(HMS.minutes, t('minute'), t('minutes'));
    if (HMS.seconds) {
      formattedTime += ` and `;
      addTime(HMS.seconds, t('second'), t('seconds'));
    }
  } else {
    addTime(HMS.hours, t('hour'), t('hours'));
    if (HMS.minutes) {
      formattedTime += ` and `;
      addTime(HMS.minutes, t('minute'), t('minutes'));
    }
    if (HMS.seconds) {
      formattedTime += ` and `;
      addTime(HMS.seconds, t('second'), t('seconds'));
    }
  }
  return formattedTime;
};

/**
 * This function checks if a string is in the 'HH:mm' format.
 *
 * It uses a regular expression to match a time string in the 'HH:mm' format. The regular expression matches a string that starts with a number between 00 and 23 (for the hours), followed by a colon, and ends with a number between 00 and 59 (for the minutes).
 *
 * The function uses the `test` method to check if the `time` string matches this regular expression. The `test` method returns `true` if the string matches the regular expression, and `false` otherwise.
 *
 * @param {string} time - The time string to check.
 * @returns {boolean} `true` if the string is in the 'HH:mm' format, `false` otherwise.
 */
export function isValidTimeFormat(time: string) {
  // Regular expression to match time in 'HH:mm' format
  const timeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/;

  // Test the time string against the regular expression
  return timeFormat.test(time);
}

/**
 * This function turns a time string in the 'HH:mm' format into
 * a date object with today's date and the specified time.
 *
 * @param {string} time - The time string in the 'HH:mm' format.
 * @returns {Date | undefined} A `dayjs` object with today's date and the specified time,
 * or `undefined` if the time string is not in the correct format.
 */
export function turnTimeStringIntoDate(time: string): Date | undefined {
  if (!isValidTimeFormat(time)) return undefined;
  const [hours, minutes] = time.split(':');
  return dayjs().hour(parseInt(hours)).minute(parseInt(minutes)).toDate();
}

/**
 * To open history browser in a new window
 *
 * @param Data Camera ID, JS date time, window features
 * @returns
 */

export const openInNewWindow = (url: string, target: string, windowFeatures?: string) => {
  const defaultWindowFeatures = 'width=1000,height=700,left=200,top=200';
  window.open(url, target, windowFeatures || defaultWindowFeatures);
};

/**
 * To get timezone
 *
 * @returns Timezone
 */

export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * To open live video in a new window
 *
 * @param Data Camera ID, window features
 * @returns
 */

/**
 * Convert time string (hh:mm) to JS date
 *
 * @param timeString Time string in hh:mm format to date
 * @returns JS date
 */

export const convertTimeStringToDate = (timeString: string) => {
  const [hours, minutes] = timeString.split(':');

  const date = new Date();

  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));

  return date;
};

/**
 * Get initial active cluster index from an array of event clusters.
 *
 * @param clusters An array of event clusters
 * @returns Initial active cluster index
 */

export const getInitialActiveClusterIndex = (clusters: EventCluster[], isOldest = false) => {
  const initialClusterIndex = 0;
  let activeClusterIndex = initialClusterIndex;
  const initialIndex = isOldest ? initialClusterIndex : clusters.length - 1;
  const condition = (index: number) => (isOldest ? index <= clusters.length - 1 : index >= initialClusterIndex);

  for (let i = initialIndex; condition(i); i = isOldest ? i + 1 : i - 1) {
    if (clusters[i].count) {
      activeClusterIndex = i;
      break;
    }
  }

  return activeClusterIndex;
};

/**
 * Determines the business type based on the given size.
 *
 * @param {number} size - The total size used to determine the business type.
 * @returns {string|null} - Returns the business type as a string:
 *                          'small_business' for size between 0 and 20,
 *                          'normal_business' for size between 21 and 99,
 *                          'large_business' for size between 100 and 499,
 *                          'corporate_business' for size between 500 and 999,
 *                          'corporate_plus_business' for size 1000 and above.
 *                          Returns null if the size does not match any category.
 */
export const getBusinessType = (size: number) => {
  if (size > 0 && size <= 20) return 'small_business';
  if (size > 20 && size <= 100) return 'normal_business';
  if (size > 100 && size <= 500) return 'large_business';
  if (size > 500 && size <= 1000) return 'corporate_business';
  if (size > 1000) return 'corporate_plus_business';
  return null;
};

/**
 *  replace empty strings and undefined values with null in an object
 *  example: const myObj: MyType = replaceEmptyStringAndUndefinedWithNull<MyType>(someObj);
 */
export function replaceEmptyStringAndUndefinedWithNull<T extends object>(
  obj: T
): { [K in keyof T]: T[K] extends string | undefined ? string | null : T[K] } {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, value === '' || value === undefined ? null : value])
  ) as { [K in keyof T]: T[K] extends string | undefined ? string | null : T[K] };
}

/**
 *  The return type `value is T` is a TypeScript type predicate.
 *  It informs TypeScript that the function will return `true` if the passed `value` is neither `null` nor `undefined`,
 *  effectively narrowing the type of `value` from `T | null | undefined` to `T` when the function returns true.
 */
export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== undefined && value !== null;
}

/**
 * Checks if the given object is empty.
 *
 * @param {Record<string, any>} obj - The object to check.
 * @return {boolean} - Returns true if the object is empty, false otherwise.
 */
export function isEmptyObject(obj: Record<string, any>): boolean {
  return Object.keys(obj).length === 0;
}

/**
 * function to check if the devices's connection status is an error status.
 * @param {Object} device - The device data object.
 * @returns {Boolean} - Returns true if the connection status is in the offlineStatuses array, otherwise false.
 */
const offlineStatuses = ['bridgeOffline', 'deviceOffline', 'offline', 'unknown', 'error'];

export function isConnectionStatusOffline(device: ApiCameraIncludes | ApiBridgeIncludes | ApiSpeakerIncludes) {
  return offlineStatuses.includes(device?.status?.connectionStatus);
}

/**
 * Formats a number or a numeric string into a comma-separated string representation.
 * If the input is a string, it first checks if it is a valid numeric string.
 *
 * @param {string | number} num - The number or numeric string to format.
 * @return {string | null} - Returns the formatted string if valid, otherwise null.
 */
export function formatNumberWithCommas(num: string | number): string | null {
  // Check if num is a string and if it's not a valid numeric string, return null
  if (typeof num === 'string' && !/^\d+(\.\d+)?$/.test(num)) {
    return null;
  }

  // Convert num to a number in case it's a numeric string, then format
  const number = Number(num);
  return number.toLocaleString('en-US');
}

/**
 * Counts all non-online cameras as offline.
 * @param resourceStatusCounts - An object of type resourceStatusCounts.
 * @returns The total amount of online and non-online resources.
 */
export function getResourceStatuOnlineOfflineCount(resourceStatusCount: keyof ResourceStatusCounts) {
  const onlineCount = resourceStatusCount?.online || 0;
  const offlineCount =
    Object.values(resourceStatusCount || {}).reduce((acc: number, val: number) => acc + val, 0) - onlineCount;
  return { onlineCount, offlineCount };
}

/**
 * function responsible for navigating to a specific route
 * @param routerParams - An object with name of the route and optional custom parameters.
 */

export function navigate(routerParams: routerParams) {
  router.push(routerParams).catch((error) => {
    if (error.name !== 'NavigationDuplicated') {
      throw error;
    }
  });
}

// Utility function to convert hex to rgba
export function hexToRgba(hex: string, alpha = 1): string {
  let r = 0,
    g = 0,
    b = 0;

  // Ensure alpha is within the range 0 to 1
  if (alpha < 0) alpha = 0;
  if (alpha > 1) alpha = 1;

  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);

    // 6 digits
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

/**
 * Creates a span element with a specific class and text.
 * @param  content - The text or number content to be placed inside the span element.
 * @returns  The HTML string of the span element with the specified text and class.
 */
export function getBoldText(content: string | undefined) {
  return `<span class='font-weight-medium'>${content}</span>`;
}
