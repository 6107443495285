<template>
  <div>
    <eewc-loading-spinner
      v-if="appLoading || isLogoutLoading"
      class="app-loading"
      :is-loading="true"
      :size="48"
    />
    <v-app v-if="authStateStore.loggedIn && !accountStore.isAccountPermissionDenied">
      <eewc-navigation-topbar
        v-if="!appState.externalBrowser && !appState.isFullScreen && !appState.isIFrame"
        :show-arrow-icon="appState.currentRoute?.meta?.showBreadcrumbArrow"
        header-title="Dashboard"
        :clock-data="{ ...clockData, timeZone }"
        :return-arrow="returnArrow"
        :new-notification="newNotification"
        :fullscreen-tooltip="t('Fullscreen (f)')"
        :top-bar-breadcrumbs="appState.topBarBreadcrumbs"
        @emit-fullscreen="enterFullScreen()"
      >
        <template #searchBar>
          <div class="searchbar">
            <eewc-searchbar
              v-if="isSearchBarVisible"
              v-model="searchQuery"
              :collections="[]"
              :show-collection="false"
              :placeholder="filterPlaceHolder"
              icon="$icon_funnel"
              @input="applySearch"
            />
          </div>
        </template>
        <eewc-tooltip
          v-if="hasRespondersShared"
          :text="t('Deactivate responder share')"
        >
          <template #content="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <eewc-button-common
                :icon-size="32"
                append-icon="$icon_first_responder"
                :icon="true"
                on-image
                class="mr-5"
                data-testid="first-responder-btn"
                :disabled="deactivatingShare"
                color="negative"
                type="clear"
                @click="deActivateResponderShare"
              />
            </div>
          </template>
        </eewc-tooltip>
        <eewc-tooltip :text="t('Enhanced web interface info')">
          <template #content="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <eewc-button-common
                :icon-size="32"
                append-icon="$icon_celebration"
                :icon="true"
                on-image
                class="mr-5"
                id="welcome-screen-btn"
                color="accent"
                type="clear"
                @click="showWelcomeScreen = true"
              />
            </div>
          </template>
        </eewc-tooltip>
        <eewc-button-common
          data-testid="top-bar-btn-classic-webapp"
          color="accent"
          @click="window.open('http://login.eagleeyenetworks.com', '_self')"
        >
          {{ t('Classic interface') }}
        </eewc-button-common>
        <eewc-button-common
          v-if="!isWhiteLabelEnabled"
          type="clear"
          append-icon="$icon_help"
          color="accent"
          class="ml-3"
          @click="navigateToHelpCenter"
        >
          {{ t('Help') }}
        </eewc-button-common>
        <welcome-screen
          v-if="showWelcomeScreen"
          :show="showWelcomeScreen"
          @close="closeWelcomeScreen"
        ></welcome-screen>

        <notification-dialog />
      </eewc-navigation-topbar>
      <eewc-navigation-menubar
        v-if="!appState.externalBrowser && !appState.isFullScreen && !appState.isIFrame"
        :items="navigationData.items"
        :help-center="navigationData.helpCenter"
        :help-items="navigationData.helpItems"
        :email="navigationData.email"
        :user-items="navigationData.userItems"
        :phone="navigationData.phone"
        :support-email="navigationData.supportEmail"
        :support-phone="navigationData.supportPhone"
        :user-name="navigationData.userName"
        :logo-small="applyBranding && brandingInfo?.logoSmall ? '' : navigationData.logoSmall"
        :logo-small-width="navigationData.logoSmallWidth"
        :logo-small-height="navigationData.logoSmallHeight"
        :logo-big="applyBranding && brandingInfo?.logoSmall ? brandingInfo?.logoSmall : navigationData.logoBig"
        :logo-big-width="navigationData.logoBigWidth"
        :logo-big-height="navigationData.logoBigHeight"
        :logo-margin-s-m="navigationData.logoMarginSM"
        :logo-margin-m-d="navigationData.logoMarginMD"
        :logo-margin-l-g="navigationData.logoMarginLG"
        :logo-margin-x-l="navigationData.logoMarginXL"
        :tooltips="navigationData.tooltips"
        :is-mini="persistedState.miniMenuBar"
        :is-logo-loading="!accountStore.account"
        :is-e-e-n="accountStore.isEEN"
        :alt-logo-big="applyBranding && brandingInfo?.logoSmall ? brandingInfo?.logoSmall : navigationData.logoBig"
        :alt-logo-small="applyBranding && brandingInfo?.logoSmall ? '' : navigationData.logoSmall"
        @get-subItemId-clicked="assignItemClickedtoRouter"
        @logout="authStateStore.logout"
        @onusershiftdblclick="appState.updateShowHiddenFeatures(true)"
        @updateMini="persistedState.setMiniMenuBar"
        @on-click-logo="onClickLogo"
        @on-shift-dblclick-logo="onShiftDoubleClickLogo"
        @on-alt-dblclick-logo="onAltDoubleClickLogo"
        @infiniteHandler="navbarInfiniteHandler"
      />
      <v-container
        fluid
        class="container-fluid"
      >
        <v-main
          id="main"
          class="container-main"
        >
          <div
            class="full-screen-wrapper"
            :class="{ 'full-screen--enabled': appState.isFullScreen }"
          >
            <notification-dialog-wrapper />
            <confirmation-dialog-wrapper />
            <router-view />
          </div>
        </v-main>
      </v-container>
      <eewc-banner-dialog
        class="offline-banner"
        color="error"
        icon="$icon_no_internet"
        :showing="noInternet"
      >
        <template #body>
          {{ t('Internet connection is lost, some items may be unavailable') }}
        </template>

        <template #buttonright>
          <eewc-button-common
            type="clear"
            color="negative"
            @click="$router.go()"
          >
            {{ t('Reload Page') }}
          </eewc-button-common>
        </template>
      </eewc-banner-dialog>
    </v-app>
    <v-app v-else-if="authStateStore.loggedIn && accountStore.isAccountPermissionDenied">
      <reseller-dashboard />
    </v-app>
  </div>
</template>

<script>
import Vue, { reactive, toRefs, onMounted, onBeforeUnmount, watch, watchEffect } from 'vue';
import { openInNewTab, goFullScreen, removeMilliseconds } from './service/helpers';
import ResellerDashboard from './components/ResellerDashboard.vue';

import {
  useUsersStore,
  useCamerasStore,
  useAuthStateStore,
  useMessagingStore,
  useNotificationsStore,
  useAppStateStore,
  useAccountStore,
  usePersistedStateStore,
  useVSPStore,
  useFirstRespondersStore,
  useVSPersistedStateStore,
} from './stores';
import { useVideoSearchStore } from '@/stores/videoSearch';
import useNavigation from './service/navigationService';
import useSearchbar from './service/useSearchbar';
import useInactivityMonitor from './service/useInactivityMonitor';
import ConfirmationDialogWrapper from '@/components/ConfirmationDialogWrapper.vue';
import NotificationDialogWrapper from '@/components/NotificationDialogWrapper.vue';
import NotificationDialog from './pages/Notifications/NotificationDialog.vue';
import WelcomeScreen from './pages/WelcomeScreen/WelcomeScreen.vue';
import useShortcuts from './service/useShortcuts';
import useWebSocketEvents from './service/useWebSocketEvents';
import { computed } from 'vue';
import { t } from '@/plugins/i18n.ts';
import router from './service/router';
import { useRoute } from 'vue-router/composables';
import Clarity from './plugins/clarity';

export default {
  name: 'App',
  components: {
    ConfirmationDialogWrapper,
    NotificationDialog,
    NotificationDialogWrapper,
    ResellerDashboard,
    WelcomeScreen,
  },
  setup() {
    const usersStore = useUsersStore();
    const camerasStore = useCamerasStore();
    const authStateStore = useAuthStateStore();
    const messagingStore = useMessagingStore();
    const notificationsStore = useNotificationsStore();
    const appState = useAppStateStore();
    const accountStore = useAccountStore();
    const persistedState = usePersistedStateStore();
    const vspStore = useVSPStore();
    const firstRespondersStore = useFirstRespondersStore();

    const videoSearchStore = useVideoSearchStore();
    const videoSearchPersistedStore = useVSPersistedStateStore();
    const state = reactive({
      appLoading: true,
      headerTitle: 'Dashboard',
      returnArrow: true,
      newNotification: true,
      clockData: undefined,
      timeZone: computed(() => usersStore.currentUserTimezone),
      window: window,
      showDashboard: false,
      showWelcomeScreen: false,
      noInternet: false,
      clockInterval: null,
      deactivatingShare: false,
      footerItems: [
        {
          title: 'Terms & Conditions',
          link: 'https://www.een.com/#/terms',
        },
        {
          title: 'Privacy Policy',
          link: 'https://www.een.com/privacy-policy/',
        },
        {
          title: 'Cookies',
          link: 'https://www.een.com/#/cookies',
        },
      ],
    });

    const route = useRoute();

    const brandingInfo = computed(() => accountStore.account?.brandingInfo);
    const isWhiteLabelEnabled = computed(() => brandingInfo.value?.isWhiteLabelEnabled);
    const hasRespondersShared = computed(() => accountStore.account?.firstResponders?.active);
    const applyBranding = computed(() => accountStore?.applyBranding);
    const showHiddenFeatures = computed(() => appState.showHiddenFeatures);

    const {
      assignItemClickedtoRouter,
      navigationData,
      onClickLogo,
      onShiftDoubleClickLogo,
      navbarInfiniteHandler,
      isLogoutLoading,
    } = useNavigation();
    const { searchQuery, applySearch, isSearchBarVisible, filterPlaceHolder } = useSearchbar();
    window.addEventListener('offline', () => (state.noInternet = true));
    window.addEventListener('online', () => (state.noInternet = false));

    onMounted(() => {
      initClarity();
      state.clockInterval = setInterval(
        () =>
          (state.clockData = {
            ...state.clockData,
            value: removeMilliseconds(new Date().toEENAccountTimeStamp().slice(10)),
          }),
        1000
      );
      document.addEventListener('fullscreenchange', exitFullScreen, false);
      setTimeout(() => {
        state.appLoading = false;
      }, 2000); // even though timeout value is 2 seconds, the spinner will be quickly hide by v-main content.
      authStateStore.loggedIn && getData();
      if (!localStorage.getItem('welcomeScreenShown')) {
        state.showWelcomeScreen = true;
      }
    });

    /**
     * Initialize clarity only for the demo user (ca0f61ab)
     * This is necessary because running Clarity in a production environment
     * without the user's consent may lead to legal issues.
     */
    function initClarity() {
      if (!config.app_clarity_integration) return;
      let watcher = null;
      watcher = watchEffect((onCleanup) => {
        if (usersStore.currentUser) {
          if (usersStore.currentUser.id === 'ca0f61ab') {
            Vue.use(Clarity);
          }
          onCleanup(() => {
            watcher();
          });
        }
      });
    }

    function enterFullScreen() {
      goFullScreen();
      appState.updateIsFullScreen(true);
    }

    function exitFullScreen() {
      if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        appState.updateIsFullScreen(false);
      }
    }

    function closeWelcomeScreen() {
      state.showWelcomeScreen = false;
      localStorage.setItem('welcomeScreenShown', true);
    }

    function navigateToHelpCenter() {
      router.push({ name: 'Help center' });
    }

    watch(
      () => authStateStore.loggedIn,
      (newVal, oldVal) => {
        if (newVal !== oldVal && newVal) {
          getData();
        }
      }
    );

    watch(
      () => appState.searchQuery,
      (newVal, oldVal) => {
        newVal !== oldVal && (searchQuery.value = appState.searchQuery);
      }
    );

    onBeforeUnmount(() => {
      clearInterval(state.clockInterval);
    });

    function fetchAccount() {
      accountStore.fetchAccount({
        include: 'timeZone,workingHours,brandingInfo,packages,security,firstResponders,roleSettings',
      });
    }

    async function getData() {
      fetchAccount();
      accountStore.fetchAccountCapabilities();
      firstRespondersStore.getPagedResponders('self');
      await usersStore.getCurrentUser();
      notificationsStore.getSpecificNotifications();
      setTimeout(() => camerasStore.updateGoogleAnalytics(), 6000);
    }

    async function deActivateResponderShare() {
      state.deactivatingShare = true;

      const status = await accountStore.patchAccount('self', {
        firstResponders: { active: false },
      });

      if (status === 204) {
        messagingStore.showSuccessMessage(t('The first responder has deactivated.'));
        fetchAccount();
      }

      state.deactivatingShare = false;
    }

    function shortcutsHandler(event) {
      if (event.shiftKey && ['F', 'f'].includes(event.key)) {
        enterFullScreen();
        return;
      }
    }

    const onAltDoubleClickLogo = () => {
      vspStore.enableReportsAndMaps(true);
      if (route.name === 'VideoSearch') {
        videoSearchStore.updateShowHiddenFaceMatchFeature(!videoSearchStore.shouldShowHiddenFaceMatchFeature);
        videoSearchPersistedStore.updateShowHiddenFaceMatchFeature(videoSearchStore.shouldShowHiddenFaceMatchFeature);
      }
    };

    useShortcuts(shortcutsHandler);
    useWebSocketEvents();
    useInactivityMonitor();
    return {
      ...toRefs(state),
      openInNewTab,
      goFullScreen,
      navigationData,
      assignItemClickedtoRouter,
      authStateStore,
      accountStore,
      messagingStore,
      appState,
      persistedState,
      onClickLogo,
      onShiftDoubleClickLogo,
      enterFullScreen,
      searchQuery,
      applySearch,
      closeWelcomeScreen,
      navigateToHelpCenter,
      isSearchBarVisible,
      filterPlaceHolder,
      t,
      brandingInfo,
      hasRespondersShared,
      deActivateResponderShare,
      applyBranding,
      navbarInfiniteHandler,
      isLogoutLoading,
      isWhiteLabelEnabled,
      onAltDoubleClickLogo,
      showHiddenFeatures,
    };
  },
};
</script>

<style lang="scss" scoped>
@import './styles/public/main.scss';

.searchbar {
  width: 358px;
}

.app-loading {
  top: 318px;
}
.container-fluid {
  height: 100%;
  padding: 0;
}

.container-main {
  height: 100%;
}

.full-screen-wrapper {
  background: white;
  > * {
    padding: 0 20px 0 20px;
  }
}

.offline-banner {
  margin-top: 76px;
}
</style>
