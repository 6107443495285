/**
 * Routes
 */

const ROUTE_PARAM_IDS = {
  alerts: 'alerts',
  rules: 'rules',
  search: 'search',
  summary: 'summary',
  vehicleList: 'vehicleList',
  reports: 'reports',
};

const ROUTE_NAMES = {
  alerts: 'Alerts',
  rules: 'Rules',
  search: 'Search',
  summary: 'Summary',
  vehicleList: 'Vehicle list',
  singleVehicleList: 'Single vehicle list',
  vehicleUploadViaCSV: 'Upload list via CSV',
  addVehicle: 'Add vehicle',
  addVehicleFromSearch: 'Add vehicle search',
  editVehicle: 'Edit vehicle',
  addRule: 'Add VSP rule',
  editRule: 'Edit VSP rule',
  vspMap: 'License plate search',
  reports: 'VSP Reports',
};

const ROUTES = {
  // Vehicle list
  singleVehicleListPage: (id: string) => `/vsp/vehicleList/${id}`,
  vehicleListAddPage: () => '/vsp/vehicleList/add',
  vehicleListsPage: () => '/vsp/vehicleList',
  vehicleAddPage: (id: string) => `/vsp/vehicleList/${id}/add-vehicle`,
  csvVehicleUpload: (id: string) => `/vsp/vehicleList/${id}/csv-upload`,
  vehicleEditPage: (listID: string, recordID: string) => `/vsp/vehicleList/${listID}/${recordID}/edit-vehicle`,
  addVehiclePage: () => `/vsp/vehicleList/add-vehicle`,

  // Rules
  editRulePage: (id: string) => `/vsp/rules/${id}/edit-rule`,
  addRulePage: () => '/vsp/rules/add-rule',
  rulesPage: () => '/vsp/rules',

  // Search
  searchPage: () => '/vsp/search',
  mapPage: () => `/vsp/search/maps`,
};

/**
 * General
 */

const TABLE_LOADING_ITEMS_LENGTH = 4;

const LPR_EVENT_DATA_TYPES = {
  accessType: 'een.lprAccessType.v1',
  croppedFrameImageUrl: 'een.croppedFrameImageUrl.v1',
  display: 'een.display.v1',
  fullFrameImageUrl: 'een.fullFrameImageUrl.v1',
  lprDetection: 'een.lprDetection.v1',
  objectDetection: 'een.objectDetection.v1',
  userData: 'een.userData.v1',
  vehicleAttributes: 'een.vehicleAttributes.v1',
};

const UNKNOWN_EVENT_COUNT = -1;

const VEHICLE_RECORD_CSV_HEADER_INDEX_OFFSET = 2;

const DEFAULT_TABLE_HEADER_SKELETON = [
  { text: '-', skeletonWidth: '275px' },
  { text: '-', skeletonWidth: '275px' },
  { text: '-', skeletonWidth: '200px' },
  { text: '-', skeletonWidth: '200px' },
  { text: '-', skeletonWidth: '200px' },
  { skeletonWidth: '0px' },
  { skeletonWidth: '0px' },
];

const ALPHA_NUMERIC_HYPHEN_REGEX = /[^A-Za-z0-9*-]/;

const ALPHA_NUMERIC_REGEX = /[A-Za-z0-9]/;
const SPECIAL_CHAR_REGEX = /[$&+,:;=?@#|'<>.^()%!_\- ]/g;

const V3_URL_SUBSTRING = '/api/v3.0/';

enum VSP_RULES_FILTERTYPE {
  plate = 'plate',
  vehicleList = 'vehicleList',
  anyPlateExcept = 'anyPlateExcept',
}

export {
  ALPHA_NUMERIC_HYPHEN_REGEX,
  ALPHA_NUMERIC_REGEX,
  DEFAULT_TABLE_HEADER_SKELETON,
  LPR_EVENT_DATA_TYPES,
  ROUTE_NAMES,
  ROUTE_PARAM_IDS,
  ROUTES,
  SPECIAL_CHAR_REGEX,
  TABLE_LOADING_ITEMS_LENGTH,
  UNKNOWN_EVENT_COUNT,
  VEHICLE_RECORD_CSV_HEADER_INDEX_OFFSET,
  VSP_RULES_FILTERTYPE,
  V3_URL_SUBSTRING,
};
